var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-app-bar",
        {
          staticClass: "app-bar pl-1",
          attrs: {
            app: "",
            "clipped-left": "",
            extended: _vm.isStoreSearchEnabled,
            color: "white",
          },
          scopedSlots: _vm._u(
            [
              _vm.isStoreSearchEnabled
                ? {
                    key: "extension",
                    fn: function () {
                      return [
                        _c(
                          "v-row",
                          { attrs: { "no-gutters": "" } },
                          [
                            _c(
                              "v-col",
                              { attrs: { md: "8", lg: "6", sm: "12" } },
                              [
                                _c("store-search-new", {
                                  attrs: {
                                    airport: _vm.airport,
                                    waypoint: _vm.waypointId,
                                  },
                                  on: {
                                    "airport-change": function ($event) {
                                      _vm.airport = $event
                                    },
                                    "waypoint-change": _vm.onWaypointChange,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("v-app-bar-nav-icon", {
            staticClass: "d-flex d-sm-none",
            on: {
              click: function ($event) {
                _vm.appBarDrawer = !_vm.appBarDrawer
              },
            },
          }),
          _c(
            "v-toolbar-title",
            [
              _c("router-link", {
                staticClass: "app-bar__logo",
                attrs: { to: {} },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "d-none d-sm-flex" },
            _vm._l(_vm.appBarLinks, function (link) {
              return _c(
                "v-btn",
                {
                  staticClass: "app-bar__button ml-2",
                  attrs: {
                    to: link.to,
                    text: !link.warn,
                    color: link.warn ? "error" : "default",
                    "active-class": "app-bar__button-active",
                    rounded: "",
                  },
                },
                [_vm._v(" " + _vm._s(link.display) + " ")]
              )
            }),
            1
          ),
          _c("v-spacer"),
          _vm._l(_vm.userDropdowns, function (x) {
            return _c(
              "v-menu",
              {
                attrs: {
                  rounded: "",
                  "close-on-content-click": false,
                  bottom: "",
                  "offset-y": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g({ attrs: { text: "" } }, on),
                            [
                              _vm._v(" " + _vm._s(x.header()) + " "),
                              _c("v-icon", { attrs: { dark: "", right: "" } }, [
                                _vm._v("mdi-chevron-down"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _c(
                  "v-list",
                  _vm._l(x.items, function (item, index) {
                    return _c(
                      "v-list-item",
                      { key: index, on: { click: item.run } },
                      [
                        _c("v-list-item-action", [
                          _vm._v(_vm._s(item.display)),
                        ]),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "sidebar-navigation",
          attrs: {
            app: "",
            clipped: "",
            permanent: false,
            "disable-resize-watcher": "",
          },
          model: {
            value: _vm.appBarDrawer,
            callback: function ($$v) {
              _vm.appBarDrawer = $$v
            },
            expression: "appBarDrawer",
          },
        },
        _vm._l(_vm.appBarLinks, function (link) {
          return _c(
            "v-btn",
            {
              staticClass: "app-bar__button ml-2",
              attrs: {
                to: link.to,
                text: "",
                "active-class": "app-bar__button-active",
              },
            },
            [_vm._v(" " + _vm._s(link.display) + " ")]
          )
        }),
        1
      ),
      _vm.hasSidebarSlot
        ? _c(
            "v-navigation-drawer",
            {
              staticClass: "sidebar-navigation",
              attrs: { app: "", clipped: "" },
              model: {
                value: _vm.drawer,
                callback: function ($$v) {
                  _vm.drawer = $$v
                },
                expression: "drawer",
              },
            },
            [_c("router-view", { attrs: { name: "sidebar" } })],
            1
          )
        : _vm._e(),
      _c(
        "v-main",
        [
          _c(
            "v-banner",
            {
              attrs: {
                "single-line": "",
                elevation: "24",
                "text-color": "warning",
              },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: { click: _vm.handleLogout },
                        },
                        [_vm._v(" Log In Again ")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.accessTokenExpired,
                callback: function ($$v) {
                  _vm.accessTokenExpired = $$v
                },
                expression: "accessTokenExpired",
              },
            },
            [
              _c(
                "v-icon",
                {
                  attrs: { slot: "icon", color: "warning", size: "36" },
                  slot: "icon",
                },
                [_vm._v(" mdi-account-alert ")]
              ),
              _vm._v(
                " Your session has expired. Logout before making changes. "
              ),
            ],
            1
          ),
          _vm.hasSidebarSlot
            ? _c(
                "v-btn",
                {
                  staticClass:
                    "sidebar-control-button mt-1 ml-4 d-block d-lg-none",
                  attrs: { elevation: "3", icon: "", "x-small": "" },
                  on: {
                    click: function ($event) {
                      _vm.drawer = !_vm.drawer
                    },
                  },
                },
                [
                  _c("v-icon", [
                    _vm._v(
                      _vm._s(
                        _vm.drawer ? "mdi-chevron-left" : "mdi-chevron-right"
                      )
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c("router-view"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }