<template>
  <v-app>
    <v-app-bar
      app
      clipped-left
      class="app-bar pl-1"
      :extended="isStoreSearchEnabled"
      color="white"
    >
      <v-app-bar-nav-icon
        class="d-flex d-sm-none"
        @click="appBarDrawer = !appBarDrawer"
      />
      <v-toolbar-title>
        <router-link :to="{}" class="app-bar__logo" />
      </v-toolbar-title>
      <div class="d-none d-sm-flex">
        <v-btn
          v-for="link of appBarLinks"
          :to="link.to"
          :text="!link.warn"
          :color="link.warn ? 'error' : 'default'"
          class="app-bar__button ml-2"
          active-class="app-bar__button-active"
          rounded
        >
          {{ link.display }}
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <v-menu
        v-for="x of userDropdowns"
        rounded
        :close-on-content-click="false"
        bottom
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            {{ x.header() }}
            <v-icon dark right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in x.items"
            :key="index"
            @click="item.run"
          >
            <v-list-item-action>{{ item.display }}</v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
      <template v-slot:extension v-if="isStoreSearchEnabled">
        <v-row no-gutters>
          <v-col md="8" lg="6" sm="12">
            <store-search-new
              :airport="airport"
              :waypoint="waypointId"
              @airport-change="airport = $event"
              @waypoint-change="onWaypointChange"
            />
          </v-col>
        </v-row>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-model="appBarDrawer"
      app
      clipped
      class="sidebar-navigation"
      :permanent="false"
      disable-resize-watcher
    >
      <v-btn
        v-for="link of appBarLinks"
        :to="link.to"
        text
        class="app-bar__button ml-2"
        active-class="app-bar__button-active"
      >
        {{ link.display }}
      </v-btn>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-if="hasSidebarSlot"
      v-model="drawer"
      app
      clipped
      class="sidebar-navigation"
    >
      <router-view name="sidebar" />
    </v-navigation-drawer>

    <v-main>
      <v-banner
        single-line
        elevation="24"
        text-color="warning"
        v-model="accessTokenExpired"
      >
        <v-icon slot="icon" color="warning" size="36">
          mdi-account-alert
        </v-icon>
        Your session has expired. Logout before making changes.
        <template v-slot:actions>
          <v-btn color="primary" text @click="handleLogout">
            Log In Again
          </v-btn>
        </template>
      </v-banner>
      <v-btn
        v-if="hasSidebarSlot"
        @click="drawer = !drawer"
        class="sidebar-control-button mt-1 ml-4 d-block d-lg-none"
        elevation="3"
        icon
        x-small
      >
        <v-icon>{{ drawer ? "mdi-chevron-left" : "mdi-chevron-right" }}</v-icon>
      </v-btn>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import StoreSearchNew from "components/store_search_new";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "management",
    components: {
        StoreSearchNew,
    },
    data() {
        return {
            waypointId: null,
            airport: null,
            drawer: null,
            appBarDrawer: false,
            showBanner: true,
        };
    },
    mounted() {
        this.waypointId = this.routeWaypointId
            ? Number.parseInt(this.routeWaypointId)
            : this.routeWaypointId;
    },
    computed: {
        ...mapGetters(["user", "accessTokenExpired", "superuser"]),
        routeWaypointId() {
            return this.$route.params.store;
        },
        appBarLinks() {
            let links = [
                {
                    to: { name: "store" },
                    display: "Store Admin",
                    sublinks: [],
                },
            ];

            if (
                this.user != undefined &&
        this.user.email != undefined &&
        this.superuser
            ) {
                links.push({
                    to: { name: "operations" },
                    display: "Operations",
                    sublinks: [],
                });
            }

            // if (this.user != undefined && this.user.email != undefined) {
            //     links.push({
            //         to: {name: 'store-information'},
            //         display: "Create Store",
            //         sublinks: [],
            //     })
            // }

            // links.push({
            //     to: { path: "/languages" },
            //     display: "Languages",
            //     sublinks: [],
            // });

            return links;
        },
        hasSidebarSlot() {
            return _.some(
                this.$route.matched,
                (x) => x.components && x.components.sidebar
            );
        },
        userHeader() {
            if (!this.user) {
                return "";
            }
            if (this.user.firstName != undefined)
                return "Hi " + this.user.firstName + "!";
            return "Hi " + this.user.name + "!";
        },
        isStoreSearchEnabled() {
            if (this.$route.name === "create-store") {
                return false;
            }

            return (
                this.$route.path.includes("store") ||
        this.$route.path.includes("languages")
            );
        },
        userDropdowns() {
            if (!this.user) {
                return [];
            }

            return [
                {
                    header: () => this.userHeader,
                    items: [
                        {
                            display: "Logout",
                            run: () => {
                                this.handleLogout();
                            },
                        },
                    ],
                },
            ];
        },
    },
    watch: {
        routeWaypointId(val) {
            if (val == this.waypointId) return;
            this.waypointId = val;
        },
        waypointId(val, old) {
            if (val != old && (old == null || old == undefined)) {
                this.onLocationChange(val);
            }
        },
    },
    methods: {
        ...mapActions(["logout"]),
        handleLogout() {
            this.logout();

            this.$router.replace({ name: "login" });
        },
        onLocationChange(waypointId) {
            if (this.$route.name === "languages") {
                this.$router.push({
                    name: "store-languages",
                    params: { store: waypointId },
                });
            }
        },
        onWaypointChange(wp) {
            if (wp != this.waypointId)
                this.$router.push({
                    name: "store-products",
                    params: { store: wp },
                });
            this.waypointId = wp
        },
    },
};
</script>

<style scoped lang="scss">
@import "../scss/variables";

.app-bar {
  &__logo {
    background-image: url("../img/g_hub_white.svg");
    width: 85px;
    height: 41px;
    background-position: left center;
    background-repeat: no-repeat;
    cursor: pointer;
    display: block;

    &:hover {
      background-image: url("../img/ghub_logo_active.svg");
    }
  }
  &__button {
    &-active {
      background-color: $brand_color;
      color: $white;
    }
  }
}

.sidebar-navigation {
  background-color: $grey_light_2;
  padding: 5px;
}

.sidebar-control-button {
  position: fixed;
  background: #f9f9f9;
  z-index: 3;
  left: -23px;
}
</style>
